import { useEffect, useRef } from 'react';
import { configuration } from '@environments/env';
import { useAuth } from '@providers/AuthProvider';

interface IMarkupViewerProps {
  documentId: string;
  jwt: string | null;
  title?: string | null;
  onClose?: () => void;
}

export const MarkupViewer = (props: IMarkupViewerProps) => {
  const { documentId, jwt, title, onClose = undefined } = props;
  const containerRef = useRef(null);

  const { user } = useAuth();

  useEffect(() => {
    // Check if DOCUMENT_ENGINE_URL is not undefined
    if (!configuration.DOCUMENT_ENGINE_URL) {
      console.error('Error: DOCUMENT_ENGINE_URL is undefined');
      if (onClose) {
        onClose();
      }
      return;
    }

    const container = containerRef.current; // This `useRef` instance will render the PDF.

    let PSPDFKit, instance;

    (async function () {
      PSPDFKit = await import('pspdfkit');

      PSPDFKit.unload(container); // Ensure that there's only one PSPDFKit instance.

      const toolbarItems = [
        { type: 'sidebar-thumbnails' },
        { type: 'sidebar-annotations' },
        { type: 'pager' },
        { type: 'multi-annotations-selection' },
        { type: 'pan' },
        { type: 'zoom-in' },
        { type: 'zoom-out' },
        { type: 'zoom-mode' },
        { type: 'spacer' },
        { type: 'text', preset: 'redtext' },
        { type: 'line', preset: 'redline' },
        { type: 'arrow', preset: 'redlineArrow' },
        { type: 'rectangle', preset: 'redline' },
        { type: 'cloudy-rectangle', preset: 'redlineCloud' },
        { type: 'ellipse', preset: 'redline' },
        { type: 'polygon', preset: 'redline' },
        { type: 'polyline', preset: 'redline' },
        { type: 'export-pdf' },
      ];

      instance = await PSPDFKit.load({
        // Use the public directory URL as a base URL. PSPDFKit will download its library assets from here.
        baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,

        //Configuration
        serverUrl: configuration.DOCUMENT_ENGINE_URL,
        instant: false,
        autoSaveMode: PSPDFKit.AutoSaveMode.INTELLIGENT,
        enableHistory: true,
        authPayload: { jwt: jwt },
        container,
        documentId: documentId,

        //customization
        toolbarItems: toolbarItems,
        theme: PSPDFKit.Theme.DARK,
        locale: 'en', //pass along the user setting here. Otherwise default to 'en'
      });

      //set the definition of the redline preset
      instance.setAnnotationPresets(presets => {
        presets.redlineArrow = {
          ...presets.arrow,
          strokeWidth: 2,
          strokeColor: new PSPDFKit.Color({ r: 243, g: 0, b: 7 }),
        };
        presets.redline = {
          strokeWidth: 2,
          strokeColor: new PSPDFKit.Color({ r: 243, g: 0, b: 7 }),
        };
        presets.redlineCloud = {
          ...presets['cloudy-rectangle'],
          strokeWidth: 2,
          strokeColor: new PSPDFKit.Color({ r: 243, g: 0, b: 7 }),
        };
        presets.redtext = {
          fontColor: new PSPDFKit.Color({ r: 243, g: 0, b: 7 }),
        };
        return presets;
      });

      if (title) {
        instance.setToolbarItems(items => {
          items.unshift({
            type: 'custom',
            id: 'filename-markup',
            title: title,
          });
          return items;
        });
      }

      if (onClose) {
        instance.setToolbarItems(items => {
          items.push({
            type: 'custom',
            id: 'close-markup',
            title: 'Close',
            icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>',
            onPress: onClose,
          });
          return items;
        });
      }

      if (user) {
        instance.setAnnotationCreatorName(user.firstName + ' ' + user.lastName + ' (' + user.email + ')');
      }
    })();

    return () => PSPDFKit && PSPDFKit.unload(container);
  }, [documentId, jwt, title, onClose, user]);

  // This div element will render the document to the DOM.
  return <div ref={containerRef} style={{ width: '100%', height: '100vh' }} />;
};
